






import { Component, Vue } from 'vue-property-decorator';
import LoginComponent from './components/LoginComponent.vue';

@Component({
  components: {
    LoginComponent,
  },
})
export default class App extends Vue {}
