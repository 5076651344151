























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import {AES, enc, pad} from "crypto-js";


@Component
export default class LoginComponent extends Vue {
  @Prop() private msg!: string;
  private message: string = this.getMessage("DEFAULT_INFO");
  private error: boolean = false;

  private start: boolean = false;
  private state: number = 0;
  private email: string = "";
  private password: string = "";
  private newPassword: string = "";
  private newPasswordCheck: string = "";
  private pin: string = "";

  private requested_url: string = "";
  private iv: string = "";
  private key: string = "";

  private debug(text: string) {
    //console.log(text);
  }

  private getParameter(name: string): any {
    return (window as any)["login_parameters"][name];
  }
  private getMessage(name: string): any {
    return this.getParameter("messages")[name];
  }
  private checkEmail() {
    return this.email != "";
  }
  private checkPassword() {
    return this.password != "";
  }
  private checkPin() {
    return this.pin != "";
  }
  private getIvAndKey(){
    let config: any = { withCredentials: true };
    axios.post(
      `${this.getParameter("url_domain_name")}${this.getParameter(
        "url_service_login"
      )}`,
      {},
      config
    )
    .then((x:any) => {
      let data:any = x.data;
      this.iv = data.iv;
      this.key = data.key;
      //this.debug(JSON.stringify(x));
    })
    .then((x:any) => {
      this.debug(`error:${JSON.stringify(x)}`);
    })    
  }
  private tryLogin(extraParams: any) {
    let withPin: boolean = this.state > 0;

    let config: any = { withCredentials: true };

    let params: any = {
      _username: this.email,
      _password: this.password,
      _token: this.getParameter("csrf_token"),
      _state: this.state
    };
    if (withPin) {
      params._pin = this.pin;
    }
    if (extraParams) {
      params = { ...params, ...extraParams };
    }

    let msg = JSON.stringify(params);

    // the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
    let key = enc.Hex.parse(this.key);
    let iv =  enc.Hex.parse(this.iv);

    // encrypt the message
    let encrypted = AES.encrypt(msg, key, {iv:iv, padding:pad.ZeroPadding}).toString();
    return axios.post(
      `${this.getParameter("url_domain_name")}${this.getParameter(
        "url_service_login"
      )}`,
      {data : encrypted},
      config
    );
  }
  private tryLogin_ex(params: any) {
    return new Promise((resolve, reject) => {
      if (undefined != params.pin) {
        if (
          params.username === "sberthu@gmail.com" &&
          params.password === "1234" &&
          params.pin === "789"
        )
          resolve({
            success: true,
            next_step: "ok",
            error_message: ""
          });
        else {
          reject({
            success: false,
            next_step: "ask_pin",
            error_message: "Your pin code is not good, please retry"
          });
        }
      } else {
        if (
          params.username === "sberthu@gmail.com" &&
          params.password === "1234"
        )
          resolve({
            success: true,
            next_step: "ask_pin",
            error_message: ""
          });
        else {
          reject({
            success: false,
            next_step: "ask_pwd",
            error_message: "login or password are not valid "
          });
        }
      }
    });
  }
  private attemptTologin(extraParams: any = null) {
    return new Promise((resolve, reject) => {
      let withPin: boolean = this.state > 0;

      if (!withPin) {
        if (!this.checkEmail() || !this.checkPassword()) {
          this.setError(this.getMessage("EMAIL_ERROR"));
          return reject();
        }
      } else {
        if (!this.checkEmail() || !this.checkPassword() || !this.checkPin()) {
          this.setError(this.getMessage("PIN_ERROR"));
          return reject();
        }
      }
      this.tryLogin(extraParams)
        .then((x: any) => {
          //this.saveSession(x);
          return resolve(x);
        })
        .catch((x: any) => {
          //this.saveSession(x);
          return reject(x);
        });
    });
  }
  private clearMessage() {
    this.message = "";
    this.error = false;
  }
  private setError(message: string) {
    this.message = message;
    this.error = true;
  }
  private setInfo(message: string) {
    this.message = message;
    this.error = false;
  }
  private pinCodeVisibilityChanged(isVisible: boolean, entry: any) {
    if (isVisible) {
      (this.$refs.pinCode as HTMLTextAreaElement).focus();
    }
  }
  private newPasswordVisibilityChanged(isVisible: boolean, entry: any) {
    if (isVisible) {
      (this.$refs.newPassword as HTMLTextAreaElement).focus();
    }
  }

  private onBtLoginClick() {
    this.clearMessage();
    this.attemptTologin()
      .then((x: any) => {
        let data = (x as any).data;
        if (data.success) {
          if (data.loggued_in == "YES") {
            this.setInfo(this.getMessage("YOU_ARE_LOGGED_IN"));
            return this.redirect(data);
          }
          //this.debug(`ok:${JSON.stringify(x)}`);
          this.state = 1;
          this.setInfo(this.getMessage("ENTER_YOUR_PIN_CODE"));
        } else {
          //this.debug(`ok:${JSON.stringify(x)}`);
          (this.$refs.password as HTMLTextAreaElement).focus();
          this.setError(this.getMessage(data.error_message));
        }
      })
      .catch(x => {
        //this.debug(`err:${JSON.stringify(x)}`);
        let data = (x as any).data;
        (this.$refs.password as HTMLTextAreaElement).focus();
        this.setError(this.getMessage(data.error_message));
      });
  }
  private onBtPinClick() {
    this.clearMessage();
    this.attemptTologin()
      .then((x: any) => {
        let data = (x as any).data;
        if (data.success) {
          this.setInfo(this.getMessage("YOU_ARE_LOGGED_IN"));
          //this.debug(`ok:${JSON.stringify(x)}`);
          this.redirect(data);
        } else {
          //this.debug(`ok:${JSON.stringify(x)}`);
          this.setError(this.getMessage(data.error_message));
          (this.$refs.pinCode as HTMLTextAreaElement).focus();
        }
      })
      .catch(x => {
        //this.debug(`err:${JSON.stringify(x)}`);
        let data = (x as any).data;
        this.setError(data.error_message);
        (this.$refs.pinCode as HTMLTextAreaElement).focus();
      });
  }
  private onChangePasswordClick() {
    this.clearMessage();
    let me: LoginComponent = this;
    this.attemptTologin({ change_password: true })
      .then((x: any) => {
        let data = (x as any).data;
        if (data.success) {
          this.state = 4;
          this.setInfo(this.getMessage("NEW_PASSWORD_OK"));
          //this.debug(`ok:${JSON.stringify(x)}`);
        } else {
          //this.debug(`ok:${JSON.stringify(x)}`);
          this.setError(this.getMessage(data.error_message));
          (this.$refs.pinCode as HTMLTextAreaElement).focus();
        }
      })
      .catch((x: any) => {
        //this.debug(`err:${JSON.stringify(x)}`);
        let data = (x as any).data;
        (this.$refs.pinCode as HTMLTextAreaElement).focus();
        this.setError(data.error_message);
      });
  }
  private onSetNewPasswordClick() {
    this.clearMessage();
    if (this.newPassword === "" || this.newPassword != this.newPasswordCheck) {
      this.setError(this.getMessage("SET_NEW_PASSWORD_ERROR"));
      return;
    }
    if (this.newPassword.length < 8) {
      this.setError(this.getMessage("SET_NEW_PASSWORD_LENGTH_ERROR"));
      return;
    }
    this.attemptTologin({ new_password: this.newPassword })
      .then((x: any) => {
        let data = (x as any).data;
        if (data.success) {
          this.setInfo(this.getMessage("SET_NEW_PASSWORD_OK"));
          //this.debug(`ok:${JSON.stringify(x)}`);
          this.redirect(data);
        } else {
          //this.debug(`ok:${JSON.stringify(x)}`);
          this.setError(this.getMessage(data.error_message));
          (this.$refs.newPassword as HTMLTextAreaElement).focus();
        }
      })
      .catch((x: any) => {
        //this.debug(`err:${JSON.stringify(x)}`);
        let data = (x as any).data;
        (this.$refs.newPassword as HTMLTextAreaElement).focus();
        this.setError(data.error_message);
      });
  }
  private redirect(response: any) {
    let me = this;
    setTimeout((_: any) => {
      me.state = 99;
      if (response.requested_url) {
        setTimeout((_: any) => {
          document.location.href = response.requested_url;
        }, me.getParameter("redirect_time"));
      }
    }, me.getParameter("redirect_time"));
  }
  private mounted() {
    setTimeout((x: any) => {
      this.start = true;
    }, 1000);
    this.getIvAndKey();
  }
}
